import React from 'react';
import partners1 from '../../images/partener 1.svg';
import partners2 from '../../images/partener 2.svg';
import partners3 from '../../images/partener 3.svg';
import partners4 from '../../images/partener 4.svg';
import partners5 from '../../images/partener 5.svg';
import partners6 from '../../images/partener 6.svg';
import partners7 from '../../images/partener 7.svg';
import partners8 from '../../images/partener 8.svg';

export default function Partners() {
  return (
    <div>
      <div
        id='partners'
        class='partners'
      >
        <h2 class='reddish-title'>Our Partners</h2>

        <p class=' partners-description gray-description mt-2'>
          We are proud to collaborate with esteemed partners who share our
          vision and drive for excellence, enabling us to deliver innovative
          solutions and achieve mutual success.
        </p>

        <div class='partener-img-container'>
          <img
            src={partners1}
            alt='partener 1'
            class='partener-img img-1'
          />
          <img
            src={partners2}
            alt='partener 1'
            class='partener-img img-2'
          />
          <img
            src={partners3}
            alt='partener 1'
            class='partener-img img-3'
          />
          <img
            src={partners4}
            alt='partener 1'
            class='partener-img img-4'
          />
          <img
            src={partners5}
            alt='partener 1'
            class='partener-img img-5'
          />
          <img
            src={partners6}
            alt='partener 1'
            class='partener-img img-6'
          />
          <img
            src={partners7}
            alt='partener 1'
            class='partener-img img-7'
          />
          <img
            src={partners8}
            alt='partener 1'
            class='partener-img img-8'
          />
        </div>
      </div>
    </div>
  );
}
