import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';

import Footer from './components/Footer/Footer';

import Layout from './components/Layout/Layout';
import { Outlet } from 'react-router-dom';
import UpperNav from './components/UpperNav/UpperNav';

function App() {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
