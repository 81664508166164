import React, { useState } from 'react';
import Logo from '../../images/logo.svg';
import medicineImage from '../../images/medicine.svg';
import BasicAccordion from '../Accordion/Accordion';
import { Link, useLocation, useParams } from 'react-router-dom';
import UpperNav from '../UpperNav/UpperNav';
import Header from '../Header/Header';
import FruitsHeader from '../FruitsHeader/FruitsHeader';
import mainBackground from '../../images/header-background.svg';
import fruitsBackground from '../../images/carousel-1.svg';
// use location
export default function Navbar() {
  let [fruits, setFruits] = useState(true);

  return (
    <header
      id='hero'
      class='hero'
      style={{
        backgroundImage: fruits
          ? `url(${mainBackground})`
          : `url(${fruitsBackground})`,

        position: 'relative',
      }}
    >
      <div class='container'>
        <nav>
          <div class='logo-container'>
            <img
              className='logo w-100 '
              src={Logo}
              alt='logo'
            />
          </div>
          <ul class='nav-items'>
            <li id='active'>
              <Link to={'/home'}>Home</Link>
            </li>

            {/* <li>
              <div className='dropdown'>
                <button className='dropbtn'>Services</button>
                <div className='dropdown-content'>
                  <span href='#'>
                    <BasicAccordion />
                  </span>
                  <Link to={'engineeringconsultancy'}>
                    Engineering Consultancy
                  </Link>
                </div>
              </div>
            </li> */}

            <li>
              <a href='#contact'>Contact Us</a>
            </li>
          </ul>
        </nav>
        <Header />
        {/* <FruitsHeader /> */}
      </div>
    </header>
  );
}
