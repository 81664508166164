import React from 'react';

const Map = () => {
  return (
    <div>
      <iframe
        className='map my-5'
        src='https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3454.382427103655!2d31.0097217755532!3d30.025884574933126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAxJzMzLjIiTiAzMcKwMDAnNDQuMyJF!5e0!3m2!1sen!2seg!4v1697362043054!5m2!1sen!2seg'
        width='600'
        height='450'
        style={{ border: '0' }}
        allowFullScreen=''
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe>
    </div>
  );
};

export default Map;
