import React from 'react';

// import MainCarousel from './components/MainCarousel/MainCarousel';

// import Contact from './components/Contact/Contact';
// import Partners from './components/Partners/Partners';

// import Events from './components/Events/Events';
// import About from './components/About/About';
import MainCarousel from '../MainCarousel/MainCarousel';
import Contact from '../Contact/Contact';
import Partners from '../Partners/Partners';
import Events from '../Events/Events';
import About from '../About/About';
import Map from '../Map/Map';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

export default function Layout() {
  return (
    <div className='container'>
      <MainCarousel />
      <About />
      <Events />
      <Contact />
      <Partners />
      <Map />
    </div>
  );
}
