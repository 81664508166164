import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

export default function Contact() {
  const form = useRef();

  const ContactForm = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_mmkyhqu',
        'template_xcqj1sa',
        form.current,
        'z0FV6C7Gv3A8u6Due'
      )
      .then(
        (response) => {
          // Display the modal message
          document.getElementById('modal').style.display = 'block';
          setTimeout(() => {
            document.getElementById('modal').style.display = 'none';
          }, 3000);
        },
        (error) => {
          console.error('Email sending failed:', error);
          alert('Email sending failed. Please try again.');
        }
      );
  };

  return (
    <>
      <h2
        className='contact-title reddish-title'
        id='contact'
      >
        CONTACT US
      </h2>

      <h4 className='contact-subtitle'>
        Let's Connect and Make Your Business Thrive!
      </h4>
      <p className='contact-description'>We're Just a Message Away</p>

      <form
        onSubmit={ContactForm}
        ref={form}
      >
        <label
          className='name-label py-1'
          htmlFor='name'
        >
          Name:
        </label>
        <input
          type='text'
          name='name'
          required
        />

        <label className=' py-1 email'>Email:</label>
        <input
          type='email'
          name='email'
          required
        />

        <label>Phone:</label>
        <input
          type='tel'
          name='phone'
          required
        />

        <label className=' py-1 company-name-label'>Company Name:</label>
        <input
          type='text'
          name='companyName'
        />

        <label className=' py-1 py-1'>Message:</label>
        <textarea
          name='message'
          className='message'
          required
        ></textarea>

        <div
          id='modal'
          className='modal-container'
        >
          <button
            className='close'
            id='close-btn'
          >
            <img
              src='images/close-btn.svg'
              alt=''
            />
          </button>
          <p className='modal-description'>
            Thank you for reaching out to us! We have received your message and
            will respond to you promptly
          </p>
        </div>

        <button
          id='submit-btn'
          className='submit'
          type='submit'
          value='send'
        >
          Submit
        </button>
      </form>
    </>
  );
}
