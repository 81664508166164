import React from 'react';
import aboutImg from '../../images/about.svg';

export default function AboutJsx() {
  return (
    <div className=' container row '>
      <div className='about-container'>
        <div className='about  me-lg-4'>
          <h3 className='about-title pt-3'>About Us</h3>
          <p className='about-description pb-3'>
            At Sammore, we're your global trade partner, specializing in frozen
            produce, medical supplies, and cutting-edge machinery for the
            pharmaceutical and food industries. Dive into our website to explore
            our commitment to excellence in international trade !
          </p>
        </div>

        <div className='about-image  ms-4'>
          <img
            src={aboutImg}
            alt='shipping products'
            className='w-100'
          />
        </div>
      </div>
    </div>
  );
}
