import React from 'react';
import footerImg from '../../images/Footer.svg';
export default function Footer() {
  return (
    <div className=''>
      <footer>
        <div class='container'>
          {/* start contact div  */}
          <div className='footer-contact'>
            <div className='footer-contact-container p-5'>
              <h3 className='footer-title footer-contact-title'>Contact Us</h3>
              <p className='footer-contact-description footer-description'>
                Your inquiries are important to us. Reach out to our dedicated
                team through the information below, and let's start a
                conversation that shapes a seamless partnership
              </p>
            </div>
          </div>

          {/* end contact div  */}

          {/* start footer-content div  */}
          <div className=' footer-items-container'>
            {/* start Footer Item */}
            <div className=' footer-item'>
              <h3 className='footer-title blue-title'>Head office (Cairo)</h3>
              <div className='d-flex footer-description '>
                <i class='fa-solid fa-location-dot mt-1 me-3  '></i>

                <p className='footer-description footer-location'>
                  Building 4, 2nd Floor, office 204, Capital Business Park,
                  Sheik Zayed City, Giza, Egypt
                </p>
              </div>

              <p className='footer-description footer-location '>
                <div className='footer-phone'>
                  <i class='fa-solid fa-mobile-screen me-2 my-2'></i>
                  <span> (+20) 1001230043</span>
                </div>

                <div className='footer-landline'>
                  <i class='fa-solid fa-phone me-2 my-4 '></i>
                  <span> 0238525466 </span>
                </div>
              </p>
              <p className='footer-description footer-location  align-items-center  gap-3'>
                <div className='emails'>
                  <i class='fa-regular fa-envelope me-2 '></i>
                  <span> info@sammore-eg.com</span>
                </div>
                <div className='emails'>
                  <i class='fa-regular fa-envelope me-2 '></i>
                  <span> s.afotoh@sammore-eg.com</span>
                </div>
              </p>
            </div>
            {/* end footer item */}

            {/* start Footer Item */}
            <div className=' footer-item'>
              <h3 className='footer-title blue-title'>Alexandria office </h3>
              <div className='d-flex footer-description '>
                <i class='fa-solid fa-location-dot mt-1 me-3  '></i>

                <p className='footer-description footer-location'>
                  1 Mostafa Fahmy St. Gleem Square, Alexandria, Egypt
                </p>
              </div>
              <div className='d-flex align-items-center footer-landline footer-description '>
                <i class='fa-solid fa-phone me-2  '></i>
                <span className='footer-description'>00203 5869073</span>
              </div>
            </div>
            {/* end footer item */}

            {/* start Footer Item */}
            <div className=' footer-item'>
              <h3 className='footer-title blue-title'>Panama office </h3>
              <div className='d-flex footer-description '>
                <i class='fa-solid fa-location-dot mt-1 me-3  '></i>

                <p className='footer-description footer-location'>
                  PH Denovo, 4th Floor, Office 400-A, Samuel Lewis Avenue,
                  Panama, Republic of Panama
                </p>
              </div>
            </div>
            {/* end footer item */}
          </div>
          {/* end footer-content div  */}
        </div>
      </footer>
    </div>
  );
}
