import React from 'react';
import { register } from 'swiper/element/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { EffectFade, Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css/effect-fade';
import 'swiper/css/bundle';
import firstEvents from '../../images/events-1.svg';
import secondEvents from '../../images/events-2.svg';
import thirdEvents from '../../images/events-3.svg';
import fourthEvents from '../../images/events-4.svg';
import fifthEvents from '../../images/events-5.svg';
import sixthEvents from '../../images/events-6.svg';
import seventhEvents from '../../images/events-7.svg';
import eighthEvents from '../../images/events-8.svg';
import ninthEvents from '../../images/events-9.svg';

// Import Swiper React components

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export default function Events() {
  return (
    <div id='events'>
      <h2 class='contact-title reddish-title '>Events & Conferences </h2>
      <>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={3}
          slidesPerGroup={3}
          navigation={false}
          Autoplay={true}
          Speed={100}
          pagination={{ clickable: true }}
          style={{
            '--swiper-pagination-color': '#ad1a1a',
            '--swiper-pagination-bullet-inactive-color': '#999999',
            '--swiper-pagination-bullet-inactive-opacity': '1',
            '--swiper-pagination-bullet-size': '14px',
            '--swiper-pagination-bullet-horizontal-gap': '5px',
          }}
          className='p-4 '
        >
          <SwiperSlide>
            <img
              src={firstEvents}
              className='w-100'
            />
            <p className='about-description mx-auto mt-2'>
              Minister of EDA Egypt, Dr. Tamer Essam in Riyadh October 2023
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className='w-100'
              src={thirdEvents}
            />
            <p className='about-description mx-auto mt-2'>
              CPHI meeting, Barcelona 2016{' '}
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <img
              className='w-100'
              src={fourthEvents}
            />
            <p className='about-description mx-auto mt-2'>
              Prime minister, Dr. Ahmed Nazif and minister of MOH, Dr. Hatem
              Elgabaly, 2008 (in EPCI-Hikma Bani-Sueif){' '}
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={fifthEvents}
              className='w-100'
            />
            <p className='about-description mx-auto mt-2'>
              FAT machines- Rameda
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={sixthEvents}
              className='w-100'
            />
            <p className='about-description mx-auto mt-2'>
              KSPCO pharma assesment
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={seventhEvents}
              className='w-100'
            />
            <p className='about-description mx-auto mt-2'>
              Panama embassador during Pharco visit, 2020{' '}
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={eighthEvents}
              className='w-100'
            />
            <p className='about-description mx-auto mt-2'>
              T3A pharma Assessment Asuit
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={ninthEvents}
              className='w-100'
            />
            <p className='about-description mx-auto mt-2'>
              FAT machines- Egyphar
            </p>
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  );
}

// <SwiperSlide>
// <img src={firstEvents} />
// </SwiperSlide>
// <SwiperSlide>
// <img src={secondEvents} />
// </SwiperSlide>
// <SwiperSlide>
// <img src={thirdEvents} />
// </SwiperSlide>
// <SwiperSlide>
// <img src={fourthEvents} />
// </SwiperSlide>
