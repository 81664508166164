import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout/Layout.jsx';
import Vegetables from './components/Vegetables/Vegetables.jsx';
import MedicalSupplies from './components/MedicalSupplies/MedicalSupplies.jsx';
import EngineeringConsultancy from './components/EngineeringConsultancy/EngineeringConsultancy.jsx';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Layout />,
      },

      {
        path: 'home',
        element: <Layout />,
      },

      {
        path: 'vegetables',
        element: <Vegetables />,
      },
      {
        path: 'medicalsupplies',
        element: <MedicalSupplies />,
      },
      {
        path: 'engineeringconsultancy',
        element: <EngineeringConsultancy />,
      },
    ],
  },
]);

createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
);
