import React from 'react';

export default function Header() {
  return (
    <div>
      <div class='container'>
        <div className='header-text-container'>
          <p class='intro'>
            Sammore is a specialized sourcing, trading, and distributing company
            that offers a wide range of global trading and engineering
            consultancy services
          </p>
          <p class='sub-intro '>
            Global Connections, Fresh Solutions, Endless Possibilities
          </p>
        </div>
        <div className='header-buttons-container'>
          <a
            href='#carousel'
            class='header-btn services-btn'
            id='services-btn'
          >
            Our Services
          </a>
          <a
            href='#contact'
            class='header-btn contact-btn'
            id='contact-btn'
          >
            Contact us
          </a>
        </div>
      </div>
    </div>
  );
}
