import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import firstCarousel from '../../images/carousel-1.svg';
import secondCarousel from '../../images/carousel-2.svg';
import { register } from 'swiper/element/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { EffectFade, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/effect-fade';
import 'swiper/css/bundle';

// Import Swiper React components

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules

export default function MainCarousel() {
  return (
    <>
      <h2 className='main-title my-3 ps-4 py-3'>What we offer</h2>
      <div
        className='p-2 '
        id='carousel'
      >
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={3}
          navigation={false}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
          effect={'fade'}
          style={{
            '--swiper-pagination-color': '#ad1a1a',
            '--swiper-pagination-bullet-inactive-color': '#999999',
            '--swiper-pagination-bullet-inactive-opacity': '1',
            '--swiper-pagination-bullet-size': '14px',
            '--swiper-pagination-bullet-horizontal-gap': '5px',
          }}
          className='mb-5'
        >
          <SwiperSlide className='mb-5'>
            <img src={firstCarousel} />
          </SwiperSlide>
          <SwiperSlide className='mb-5'>
            <img src={secondCarousel} />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

{
  /* <div class='slideshow-container'>
        <div class='mySlides '>
          <img src='images/carousel-1.svg' />
        </div>

        <div class='mySlides '>
          <img src='images/carousel-2.svg' />
        </div>

        <div class='mySlides '>
          <img src='images/carousel-3.svg' />
        </div>

        <div style='text-align:center'>
          <span
            class='dot'
            onclick='currentSlide(1)'
          ></span>
          <span
            class='dot'
            onclick='currentSlide(2)'
          ></span>
          <span
            class='dot'
            onclick='currentSlide(3)'
          ></span>
        </div>
      </div> */
}
